<template>
    <div>
        <main>
            <p>This website is intended as a free and easy source of information on my past projects.</p>
            <p>If you would like more information on my work, visit my gallery page at Air de Paris <a href="http://www.airdeparis.com/artists/ben-kinmont/" target="_blank">here</a>.</p>
            <p>If you would like to reach me directly, email me at <a href="mailto:bkinmont@gmail.com">bkinmont@gmail.com</a>.</p>
            <item-list jsonFile="./data/project_list.json"></item-list>
        </main>
    </div>
</template>

<script>
import ItemList from './ItemList.vue';
export default {
    data() {
        return {};
    },
    components: {
        ItemList
    }
}
</script>

<style scoped>

</style>